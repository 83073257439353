import React, { useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import API_BASE_URL from "../config";

import './username.scss';

const Username = () => {
    const [user, loading, error] = useAuthState(auth);
    const [hasUser, setHasUser] = useState(true);

    useEffect(() => {
        if (user) {
            getUser(user.uid);
        }
    }, [user]);

    const getUser = async (userid) => {
        console.log("Fetching user for:", userid);
        try {
            const response = await axios.get(`${API_BASE_URL}/users/username`, {
                params: {
                    userid: userid
                }
            });
            console.log(response.data);
            if (response.data.username === null) {
                console.log("username is null");
                setHasUser(false);
            } else {
                setHasUser(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        const newName = e.target[0].value;
        console.log("User selected:", newName);

        try {
            const response = await axios.post(`${API_BASE_URL}/users/setname`, {
                userid: user.uid,
                username: newName
            });
            if (response.status === 200) {
                console.log('Successfully set username:', response.data);
                getUser(user.uid); // Re-check if the username is null
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (loading) return null; // Optionally, handle loading state
    if (error) return <div>Error: {error.message}</div>; // Optionally, handle error state

    if (!hasUser) {
        return (
            <div className="username-overlay-container">
                {/* This is a very cool overlay */}
                <div className="username-box">
                    <div className="username-box-header">Select a Display Name</div>
                    <div className="username-box-text">This is how you'll appear on SoundOff.</div>
                    <form id="username-form" onSubmit={handleSubmit}>
                        <input className="username-input" type="text" placeholder="Select your display name" />
                        {/* <button type="submit">Submit</button> */}
                    </form>
                </div>
            </div>
        );
    }

    return null; // Return nothing if hasUser is true
};

export default Username;
