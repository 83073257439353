import logo from '../assets/soundOff.svg';
import {useNavigate} from "react-router-dom";
import {React, useState, useEffect} from "react";

import {auth} from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";

import "./navBar.scss";

const NavBar = (props) => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [navState, setNavState] = useState(true);

    useEffect(() => {
        if (!loading)
            setNavState(user);
    }, [user, loading]);

    if (props.showLogin !== undefined && props.showLogin === false) {
        return (
            <div className = "navBar">
                <img className = "logo" src = {logo}/>
            </div>
        )
    }

    return (
        <div className = "navBar">
            <img className = "logo" src = {logo} onClick = {() => navigate("/")}/>
            <AuthButton navState = {navState} />
        </div>
    )
}

const AuthButton = (props) => {
    const navState = props.navState;
    const navigate = useNavigate();

    const buttonText = navState ? "Sign Out" : "Sign In";
    const buttonClass = navState ? "auth-button sign-out" : "auth-button sign-in";

    const handleClick = () => {
        if (navState) {
            signOut(auth);
        }
        navigate("/");
    }

    return (
        <div className = {buttonClass} onClick = {handleClick}>
            {buttonText}
        </div>
    )


}

export default NavBar;