import React, { useState, useEffect } from "react";
import axios from "axios"; 
import API_BASE_URL from "../config";
import "./featured.scss";

import { GiMicrophone } from "react-icons/gi";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import {Puff} from 'react-loading-icons';
import spotify_white from "../assets/spotify_white.png";
import logo from "../assets/soundOff.svg";

const Featured = () => {
    const [type, setType] = useState('Track');
    const [featured, setFeatured] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const getFeatured = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/featured/get`);
            if (response.status === 200) {
                console.log("fetched featured data successfully");
                setFeatured(response.data);
                console.log(response.data);
            } else {
                console.error("Error:", response.status);
            }
        } catch (error) {
            console.error("Error fetching featured data:", error);
        }
    };

    const updateIndex = (direction) => {
        if (direction === 1) {
            if (activeIndex < featured.length - 1) {
                setActiveIndex(activeIndex + 1);
            } else {
                setActiveIndex(0);
            }
        }

        if (direction === -1) {
            if (activeIndex > 0) {
                setActiveIndex(activeIndex - 1);
            } else {
                setActiveIndex(featured.length - 1);
            }
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateIndex(1);
        }, 10000); // 10000 ms = 10 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    });

    useEffect(() => {
        getFeatured();
    }, []);

    if (featured.length === 0) {
        return <Puff stroke = "#A9EBFA"/>
    }

    return (
        <div className="featured-main-wrapper">
            <div className="featured-container">
                <MdOutlineKeyboardArrowLeft style={{color: "white"}} className="featured-arrow" onClick={() => updateIndex(-1)} />
                {featured.length > 0 && (
                <div className="featured-box">
                    <div className="featured-column">
                        <div className="featured-title" onClick={() => console.log(featured[activeIndex]['name'])}>{featured[activeIndex]['name']}</div>
                        <div className="info-row">
                            <div className="featured-artist">{featured[activeIndex]['artist']}</div>
                            <div className="info-container"> 
                                <div className={`type ${type}`}>{type}</div>
                                <div className="genre-container">
                                    <GiMicrophone style={{color: "#AFAFAF"}}/>
                                    <div className="genre">{featured[activeIndex]['genre']}</div>
                                </div>
                            </div>
                        </div>
                        <img src={featured[activeIndex]['image']} className="album-cover" alt="Album Cover"></img>
                    </div>
                    <div className="right-column">
                        <div className="right-image-container">
                            <div className="right-image-label">Listen on</div>
                            <img className="spotify-image" src={spotify_white} alt="Spotify Logo"></img>
                        </div>
                        <div className="right-image-container">
                            <div className="right-image-label">Make a Room</div>
                            <img className="soundoff-image" src={logo} alt="SoundOff Logo"></img>
                        </div>
                    </div>
                </div>
                )}
                <MdOutlineKeyboardArrowRight style={{color: "white"}} className="featured-arrow" onClick={() => updateIndex(1)} />
            </div>
            <div className="dots-container">
                {featured.map((item, index) => (
                    <GoDotFill 
                        key={index} 
                        style={{color: index === activeIndex ? "#A9EBFA" : "white"}}
                        onClick={() => setActiveIndex(index)}
                        className="dot" 
                    />
                ))}
            </div>
        </div>
    );
};

export default Featured;
