import {React, useState, useEffect} from "react";
import {auth, provider} from "../firebase/firebase";
import {GoogleAuthProvider, signInWithCredential, signInWithPopup, signInWithRedirect} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import NavBar from "../components/navBar";
import CreateOverlay from "../components/createOverlay";

import API_BASE_URL from "../config";

import { IoPerson } from "react-icons/io5";
import { MdOutlineMicExternalOn } from "react-icons/md";
import { BiSolidAlbum } from "react-icons/bi";

import "./create.scss";

const Create = () => {

    const [user, loading, error] = useAuthState(auth);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedType, setSelectedType] = useState(0);
    const [userid, setUserid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
		if (!user && !loading) {
            console.log("No user");
            navigate("/")
        };

        if (user) {
            setUserid(user.uid);
        }

	}, [user, loading]);

    const handleClick = (roomType) => {
        setSelectedType(roomType);
        setShowOverlay(true);
    }

    const setShow = (newState) => {
        setShowOverlay(newState);
    }

    return (
        <div className = "create-page">
            <CreateOverlay show = {showOverlay} setShow = {setShow} roomType = {selectedType} uid = {userid}/>
            <NavBar />
            <div className = "create-content">
                <div className = "create-label">Create a Room</div>
                <div className = "create-cards-container">
                    <CreateCard label = "Artists" icon = {IoPerson} handleClick = {handleClick}/>
                    <CreateCard label = "Tracks" icon = {MdOutlineMicExternalOn} handleClick = {handleClick}/>
                    <CreateCard label = "Album" icon = {BiSolidAlbum}  handleClick = {handleClick}/>
                </div>
            </div>
        </div>
    )
}

const CreateCard = (props) => {

    let roomType = 0;

    if (props.label === "Artists") {
        roomType = 1;
    }
    else if (props.label === "Tracks") {
        roomType = 2;
    }
    else if (props.label === "Album") {
        roomType = 3;
    }

    return (
        <div className = "create-card" onClick = {() => {props.handleClick(roomType)}}>
             <props.icon className = {`create-card-icon ${props.label}`}/>
            <div className = "create-card-label">{props.label}</div>
        </div>
    )
}

export default Create;