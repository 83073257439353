import React, { useEffect, useState } from "react";
import { auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/navBar";
import Username from "../components/username";
import Featured from "../components/featured";
import { CurrentView } from "../components/roomView";
import "./dashboard.scss";

const Dashboard = () => {
    const [user, loading, error] = useAuthState(auth);
    const [userid, setUserid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setUserid(user.uid);
        } else if (!loading) {
            navigate("/");
        }
    }, [user, loading, navigate]);

    return (
        <div>
            <Username />
            <NavBar />
            <div className="dashboard-content">
                <div className="section-wrapper">
                    <div className="section-label">Featured</div>
                    <Featured />
                </div>
                <div className="section-wrapper">
                    <div className="section-label">Your Rooms</div>
                    <CurrentView userid={userid} />
                </div>
                <h1>This is a very cool dashboard</h1>
            </div>
        </div>
    )
}

export default Dashboard;
