import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from 'axios';

import Landing from "./pages/landing";
import Login from "./pages/login";
import Search from "./pages/search";
import Dashboard from "./pages/dashboard";
import Create from "./pages/create";
import Join from "./pages/join";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/search" element={<Search />} />
        <Route path="/create" element={<Create />} />
        <Route path="/join" element={<Join />} />
        <Route path = "*" element = {<Navigate to = "/" />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
