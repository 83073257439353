import {React, useEffect, useState} from "react";
import {auth, provider} from "../firebase/firebase";
import {GoogleAuthProvider, signInWithCredential, signInWithPopup, signInWithRedirect} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/navBar";
import API_BASE_URL from "../config";
import GoogleButton from 'react-google-button';
import axios from 'axios';
import authImage from "../assets/auth.png";

import './login.scss';

const Login = () => {

    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();

    const [user, loading, error] = useAuthState(auth);

    async function authenticate (userid, email) {
        try {
            const response = await axios.post(`${API_BASE_URL}/users/auth`, {
                userid: userid,
                email: email
            });

            if (response.status === 200) {
                console.log('Successfully authenticated user:', response.data);
                return response.data;
            } 
            else {
                console.error('Error:', response.status);
                return [];
            }
        } catch (error) {
            console.error('Error:', error);
            return [];
        }
    }

    useEffect(() => {
        console.log(API_BASE_URL);
		if (user) {
            authenticate(user.uid, user.email).then((data) => {
                navigate("/dashboard");
            });
		}
	}, [user, loading, error]);

     const signWithGoogle = () => {
        signInWithPopup(auth, provider);
     }

    if (!user){
        return (
            <div className = "login-page-container">
                <NavBar showLogin = {false}/>
                <div className = "login-content">
                    <div className = "login-box">
                        <div className = "login-header">Continue to SoundOff</div>
                        <div className = "login-text">To get started, sign in below.</div>
                        <div className = "auth-container" onClick = {signWithGoogle}>
                            <img className = "auth-image" src = {authImage}/>
                            <div className = "auth-text">Sign in with Google</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;