import {React, useState, useEffect} from "react";
import {auth, provider} from "../firebase/firebase";
import {GoogleAuthProvider, signInWithCredential, signInWithPopup, signInWithRedirect} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/navBar";
import API_BASE_URL from "../config";
import axios from 'axios';

import "./join.scss";

const Join = () => {

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            console.log(user.uid);
        }
        else if (!loading){
            navigate("/");
        }
    }, [user, loading]);

    return (
        <div className = "join-page">
            <NavBar />
            <div className = "join-container">
                <div className = "join-label">Join a Room</div>
            </div>
        </div>
    )
}

const JoinCard = () => {
    return (
        <div>
            Join
        </div>
    )
}

export default Join;