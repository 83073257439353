import {React, useState, useEffect} from "react";

import API_BASE_URL from "../config";
import axios from "axios";

import { IoIosArrowRoundBack } from "react-icons/io";



import "./createOverlay.scss";

const CreateOverlay = (props) => {

    const isOverlayVisible = props.show;
    let roomType = "n Artists";
    let desc = "Take turns selecting artists. The better the artist's top 3 songs do, the more points you earn!"

    if (props.roomType === 1) {
        roomType = "n Artists";
    }
    else if (props.roomType === 2) {
        roomType = " Tracks";
        desc = "Take turns selecting tracks. The more popular your tracks are, the more points you earn!"
    }
    else if (props.roomType === 3) {
        roomType = "n Albums";
        desc = "Take turns selecting tracks from an album. The more popular your tracks are, the more points you earn!"
    };


    useEffect(() => {
        if (isOverlayVisible) {
          console.log("Roomtype is :", roomType);
          console.log("userid is :", props.uid);
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      }, [isOverlayVisible]);

      const handleSubmit = async (event) => {
        if (event)
            event.preventDefault();

        const roomName = event.target[0].value;
        const duration = event.target[1].value;
        console.log(roomName, duration);

        const response = await axios.post(`${API_BASE_URL}/rooms/create`, {
            "name": roomName,
            "duration": duration,
            "room_type": props.roomType,
            "userid": props.uid
        });

        console.log(response);
      }

    if (isOverlayVisible) {
        return (
            <div className = "create-overlay">
                <div className = "create-form-box">
                    <div className = "back-container">
                        <IoIosArrowRoundBack className = "back-button" onClick = {() => props.setShow(false)}/>
                    </div>
                    <div className = "create-form-header">
                        {`Create a${roomType} Room`}
                    </div>
                    <div className = "create-form-desc">
                        {desc}
                    </div>
                    <form className = "create-form" onSubmit = {handleSubmit}>
                        <input className = "room-name-input" type="text" placeholder="Select a Room Name" required/>
                        <select className = "room-duration-input"> 
                            <option className = "duration-option" value = "1">1 Week</option>
                            <option className = "duration-option" value = "2">2 Weeks</option>
                            <option className = "duration-option" value = "3">3 Weeks</option>
                            <option className = "duration-option" value = "4">4 Weeks</option>
                            <option className = "duration-option" value = "5">5 Weeks</option>
                            <option className = "duration-option" value = "6">6 Weeks</option>
                        </select>
                        <input type = "submit" value = "Create Room" className = "create-button"/>
                    </form>
                </div>
            </div>
            )  
    }  
}

export default CreateOverlay;

