// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCjlWb49UFMHL5vWgEfYO3wFI9K5wCB3Xw",
  authDomain: "soundoff-6403c.firebaseapp.com",
  projectId: "soundoff-6403c",
  storageBucket: "soundoff-6403c.appspot.com",
  messagingSenderId: "156645338564",
  appId: "1:156645338564:web:6c0d8a4e444e1cb4c10891",
  measurementId: "G-3G46B8VNTW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {app, analytics, auth, provider}