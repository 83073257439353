import {React, useState, useEffect} from "react";
import axios from 'axios';
import NavBar from "../components/navBar";
import './search.scss';

import { useNavigate } from "react-router-dom";
import {auth} from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import API_BASE_URL from "../config";

import SearchResult from '../components/searchResult';

const Search = () => {
    // const API_BASE_URL = 'https://soundoff-backend.onrender.com';  //For production build
    // const API_BASE_URL = 'http://127.0.0.1:5000'; // For testing with local API build
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    async function searchAlbums(query) {
        try {
            const response = await axios.get(`${API_BASE_URL}/albums/search`, {
            params: {
                query: query
            }
            });

            if (response.status === 200) {
                // console.log('Successfully fetched albums for query:', query, response.data);
                for (let i = 0; i < response.data.length; i++) {
                    console.log(response.data[i]['images']);
                }
                return response.data;
            } 
            else {
                console.error('Error:', response.status);
                return [];
            }
        } catch (error) {
            console.error('Error:', error);
            return [];
        }
    }

    const [albums, setAlbums] = useState([]);
    const [query, setQuery] = useState('');

    const updateQuery = (q) => {
        setQuery(q);
        // console.log(query);
    }

    const handleSubmit = async (e) => {
        if (e)
            e.preventDefault();

        console.log(user.uid);
        let response = await searchAlbums(query);
        setAlbums(response);
    }

	// useEffect(() => {
	// 	if (!user && !loading) {
    //         console.log("No user");
    //         navigate("/")
    //     };

	// }, [user, loading]);


    return (
        <div className = "search-page">
            <NavBar />
            <form onSubmit = {handleSubmit} className = "search-form">
                <input placeholder = "Search for an Album..." className = "search-input" type="text" value={query} onChange={(e) => updateQuery(e.target.value)} onKeyPress = {(e) => {if (e.key === 'Enter') {handleSubmit()}}}/>
            </form>
            <div className = "results">
                {albums.map((album) => (
                    // <div>{album.name}</div>
                    <SearchResult name = {album.name} artists = {album.artists} images = {album.images}/>
                ))}
            </div>
            {/* <button className = "sign-out" onClick = {() => {signOut(auth)}}>Sign Out</button> */}
        </div>
    )
}

export default Search;