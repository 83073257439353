import React, { useState, useEffect } from "react";
import { Puff } from 'react-loading-icons';
import axios from "axios"; 
import API_BASE_URL from "../config";
import "./roomView.scss";

import { BsFillPeopleFill } from "react-icons/bs";
import { FaClock } from "react-icons/fa";

const CurrentView = ({ userid }) => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true); // State variable for loading

    const getRooms = async () => {
        if (userid === null) {
            console.log("No userid detected");
            return;
        }

        try { 
            const response = await axios.get(`${API_BASE_URL}/rooms/get`, {
                params: { userid }
            });
            // Check and log the response data
            console.log("API response data:", response.data);
            setRooms(response.data);
            setLoading(false); // Set loading to false after rooms are fetched
            console.log("Rooms set to:", response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getRooms();
    }, [userid]);  // Add userid as a dependency here

    if (loading) {
        return <Puff stroke="#A9EBFA"/>; // Display loading animation if loading is true
    }

    return (
        <div className="room-view-container">
            {console.log("Rooms in render:", rooms)}
            {/* I am a very cool current view container */}
            {Array.isArray(rooms) && rooms.map((room, index) => (
                <RoomCard 
                    key={room.id || index}  // Use room.id if it exists, otherwise fallback to index
                    name={room.name} 
                    type={room.type} 
                    members={room.members} 
                    duration={room.duration}
                />
            ))}
        </div>
    );
}

const RoomCard = (props) => {
    const name = props.name;
    const typeNum = props.type;
    const members = props.members.length;
    const duration = props.duration;

    let type = "";

    if (typeNum === 1) {
        type = "Tracks";
    } else if (typeNum === 2) {
        type = "Artists";
    } else if (typeNum === 3) {
        type = "Album";
    }

    return (
        <div className="room-card">
            <div className="room-name">{name}</div>
            <div className="room-info-row">
                <div className={`room-type ${type}`}>{type}</div>
                <div className="room-size"><BsFillPeopleFill />{members}</div>
                <div className="room-duration"><FaClock/> {duration} Wk</div>
            </div>
        </div>
    )
}

export { CurrentView };
