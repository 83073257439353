import React from "react";
import './searchResult.scss';

const SearchResult = (props) => {
    const name = props.name;
    const artists = props.artists;
    const image = props.images[0]['url'];

    const artistToString = (artists) => {
        let artistString = "";
        for (let i = 0; i < artists.length; i++) {
            artistString += artists[i]['name'];
            if (i < artists.length - 1) {
                artistString += ", ";
            }
        }
        return artistString;
    }

    return (
        // <div>{name}, by {artistToString(artists)}</div>
        <div className = "search-result">
            {/* <img className = "album-cover" src = "https://d1csarkz8obe9u.cloudfront.net/posterpreviews/spotify-minimal-playlist-album-cover-cyan-design-template-fff58702d4947264deeb11e1e1737bd9_screen.jpg?ts=1651478054" /> */}
            <img className = "album-cover" src = {image} />
            <div className = "album-info">
                <div className = "album-name">{name}</div>
                <div className = "info-row">
                    <div className = "type-label album">Album</div>
                    <div className = "artists">{artistToString(artists)}</div>
                </div>
            </div>
        </div>
    )
}

export default SearchResult;